import moment from "moment";

export function momentDate(date) {
  return tz(moment(date))
}

function tz(momentDate) {
  return momentDate.tz('America/Los_Angeles')
}

export function dateMonth(date) {
  return momentDate(date).month();
}

export function weekDate(year, week) {
  const yearDate = moment().locale('en-us').year(year).startOf('day');
  if (week === 1) {
    // Special case. Jan 1 is always in week 1.
    return yearDate.month(0).date(1);
  }

  // Otherwise pick the date that represents the start of the week.
  const numWeeks = yearDate.weeksInYear();
  if (week > numWeeks) {
    week = numWeeks;
  }
  return yearDate.week(week).startOf("week");
}

export function dateUrlPart(date) {
  return date.format('YYYY/MM/DD');
}

export function monthUrlPart(date) {
  return date.format('YYYY/MM');
}

export function weekUrlPart(date) {
  const usDate = date.locale('en-us');
  const week = usDate.week().toString().padStart(2, "0");
  return `${usDate.format('gggg')}/week/${week}`;
}
