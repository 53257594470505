import * as React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import { Parser as HtmlToReactParser } from "html-to-react";

import "./WH3.css";
import "./WH3Nav.css";

export function WH3() {
  return (
    <div className="WH3">
      <PageContent>
        <HeaderText />
        <AlertBanner />
        <CalendarLink />
        <EmailLink />
        <GroupList />
      </PageContent>
    </div>
  );
}

export function Kennels() {
  return (
    <div className="Kennels">
      <PageContent>
        <Helmet>
          <title>Seattle-Area H3 | Kennels Info</title>
        </Helmet>
        <div className="row">
          <div className="col-sm-12 header">
            <h1>Kennel Information &amp; Contacts</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <h3>
              Send us an{" "}
              <a
                class="stretched-link"
                href="mailto:wah3@googlegroups.com?subject=WH3.org%20Inquiry"
              >
                EMAIL
              </a>{" "}
              <i class="far fa-envelope"></i>!
            </h3>
          </div>
        </div>
        <KennelsList />
        <WebGeeks />
      </PageContent>
    </div>
  );
}

class PageContent extends React.Component {
  render() {
    return (
      <div className="PageContent">
        <div
          className={`container text-center semi-opaque ${
            this.props.className ?? ""
          }`}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export class RawHtmlPage extends React.Component {
  state = {
    title: null,
  };

  url = () => {
    return this.props.path ?? this.props.match.url;
  };

  // Set the iframe's base url to target _parent.
  frameLoaded = () => {
    const iframe = document.querySelector("#raw-subpage");
    const head = iframe.contentDocument.querySelector("head");
    const title = head.querySelector("title");
    if (title) {
      this.setState({
        title: title.text,
      });
    }
    let base = head.querySelector("base");
    if (!base) {
      base = iframe.contentDocument.createElement("base");
      base.setAttribute("target", "_parent");
      head.appendChild(base);
    }
    if (base.getAttribute("target") !== "_blank") {
      base.setAttribute("target", "_parent");
    }
    if (!base.getAttribute("href")) {
      base.setAttribute("href", `${process.env.PUBLIC_URL}${this.url()}`);
    }
  };

  componentDidMount() {
    // Hook into the DOMContentLoaded event. This triggers before any additional page elements
    // are loaded (e.g. images, javascript, css). This lets us try to fix up the links as early
    // as possible.
    let frame = document.getElementById("raw-subpage");
    frame.contentWindow.addEventListener(
      "DOMContentLoaded",
      this.frameLoaded,
      true
    );
  }

  render() {
    return (
      <>
        {this.state.title && (
          <Helmet>
            <title>{this.state.title}</title>
          </Helmet>
        )}
        <PageContent className="raw-container">
          <iframe
            id="raw-subpage"
            title="Page Content"
            src={`${process.env.PUBLIC_URL}/raw${this.url()}${
              window.location.hash ?? ""
            }`}
            loading="eager"
            onLoad={this.frameLoaded}
            onError={this.frameLoaded}
          />
        </PageContent>
      </>
    );
  }
}

export class HtmlSnippetPage extends React.Component {
  state = {
    content: "",
  };

  componentDidMount() {
    fetch(this.props.path)
      .then((res) => res.text())
      .then((result) => {
        let parser = new HtmlToReactParser();
        this.setState({
          content: parser.parse(result),
        });

        // Re-set the window hash after snippet content.
        const hash = window.location.hash;
        if (hash) {
          window.location.hash = null;
          window.location.hash = hash;
        }
      });
  }
  render() {
    return (
      <div className={this.props.className}>
        <PageContent className="snip-container pt-2">
          {this.props.title && (
            <Helmet>
              <title>{this.props.title}</title>
              {this.props.stylesheet && (
                <link
                  rel="stylesheet"
                  type="text/css"
                  href={this.props.stylesheet}
                />
              )}
            </Helmet>
          )}
          {this.state.content}
        </PageContent>
      </div>
    );
  }
}

export function WH3Nav() {
  return (
    <Navbar bg="light" expand="md" fixed="top">
      <Navbar.Brand href="/">
        <img
          src="/img/WAH3.png"
          height="40px"
          alt="Green outline of Washington state with yellow foot"
        />{" "}
        WA Hash House Harriers
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse id="wh3-navbar">
        <Nav className="wh3nav text-right">
          <Nav.Link href="/calendar/">Calendar</Nav.Link>
          <Nav.Link href="/kennels/">Kennels Info</Nav.Link>
          <Nav.Link href="/harelines/">Harelines</Nav.Link>
          <Nav.Link href="/misc/">Misc. Links</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

function HeaderText() {
  return (
    <div className="HeaderText">
      <div className="row">
        <div className="col-12">
          <h1>
            Seattle-Area Hash House Harriers
            <br />
            <small className="text-muted">
              A Drinking Club with a Running Problem
            </small>
          </h1>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-12">
          Welcome to the home of hashing in the greater Seattle area.
        </div>
      </div>
    </div>
  );
}

function AlertBanner() {
  return (
    <div className="AlertBanner">
      <div className="row alert alert-warning text-center vcenter h4">
        <div className="col-2 pr-0 my-auto">
          <img
            src="/img/icons/covid.png"
            className="img-fluid align-middle"
            alt="Covid-19"
          />
        </div>
        <div className="col-8 my-auto">
          <span className="align-middle">
            <b>COVID-19 Precautions in Effect</b>
            <br />
            Click here for more info on WA's current response roadmap.
          </span>
          <a
            href="https://coronavirus.wa.gov/what-you-need-know/safe-start/whats-open"
            target="_blank"
            rel="noreferrer"
            className="stretched-link"
          >
            {" "}
          </a>
        </div>
        <div className="col-2 pl-0 my-auto">
          <img
            src="/img/icons/covid.png"
            className="img-fluid"
            alt="Covid-19"
          />
        </div>
      </div>
    </div>
  );
}

function CalendarLink() {
  return (
    <div className="row mb-2">
      <div className="col-12 pl-0">
        <h3>
          Find the next event on our{" "}
          <a className="stretched-link text-uppercase" href="/calendar.html">
            calendar
          </a>{" "}
          <i className="far fa-calendar-alt"></i>!
        </h3>
      </div>
    </div>
  );
}

function EmailLink() {
  return (
    <div className="row mb-2">
      <div className="col-12 pl-0">
        <h3>
          Send us an{" "}
          <a
            className="stretched-link text-uppercase"
            href="mailto:wah3@googlegroups.com"
          >
            e-mail
          </a>{" "}
          <i className="far fa-envelope"></i>!
        </h3>
      </div>
    </div>
  );
}

class WithKennelData extends React.Component {
  state = {
    kennels: [],
  };

  componentDidMount() {
    fetch("/data/kennels.json")
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          kennels: result,
        });
      });
  }
}

class GroupList extends WithKennelData {
  render() {
    const { kennels } = this.state;
    let kennelItems = kennels
      .filter((k) => k.schedule)
      .map((kennel) => KennelSchedule(kennel));
    return (
      <div className="row mb-2">
        <div className="col-12">
          <h4>Here are the groups represented on this site:</h4>
          <dl>{kennelItems}</dl>
        </div>
      </div>
    );
  }
}

function KennelSchedule(kennel) {
  let schedules = kennel.schedule.map((schedule) => {
    return (
      <span key={schedule}>
        ({schedule})<br />
      </span>
    );
  });
  return (
    <div key={kennel.name}>
      <dt>{kennel.name}</dt>
      <dd>
        {kennel.description}
        {schedules.length === 1 ? " " : <br />}
        {schedules}
      </dd>
    </div>
  );
}

class KennelsList extends WithKennelData {
  render() {
    const { kennels } = this.state;
    let kennelItems = kennels.map((kennel) => KennelInfo(kennel));
    return <div className="row">{kennelItems}</div>;
  }
}

function KennelInfo(kennel) {
  let allowed = ["br"];
  return (
    <div className="col-sm-6 md-2 kennel" key={kennel.id}>
      <h2>
        {kennel.name}
        {kennel.logo && (
          <img className="logo" src={kennel.logo} alt="kennel logo" />
        )}
      </h2>
      <div className="text-muted markdown">
        <ReactMarkdown>{kennel.longDescription}</ReactMarkdown>
      </div>
      {kennel.links && KennelLinks(kennel.links)}
      {kennel.infoTop && (
        <ReactMarkdown className="markdown">{kennel.infoTop}</ReactMarkdown>
      )}
      {kennel.management && Officers(kennel.management)}
      {kennel.song && KennelSong(kennel.song)}
      {kennel.infoBottom && (
        <ReactMarkdown className="markdown" allowedElements={allowed}>
          {kennel.infoBottom}
        </ReactMarkdown>
      )}
    </div>
  );
}

function KennelLinks(links) {
  return (
    <div class="KennelLinks">
      <ul>{links.map((link) => KennelLink(link))}</ul>
    </div>
  );
}

function KennelLink(link) {
  return (
    <li>
      <a href={link.href}>
        <span className={link.icon} />
        {link.text}
        <span className={link.icon} />
      </a>
    </li>
  );
}

function KennelSong(song) {
  return (
    <div className="KennelSong">
      <p className="title">{song.title}</p>
      <p className="tuneOf">(to the tune of "{song.tuneOf}")</p>
      <p className="lyrics">{song.lyrics}</p>
    </div>
  );
}

function Officers(management) {
  return (
    <div className="officers">
      <dl>
        {management.name && (
          <dt>
            <u>{management.name}:</u>
          </dt>
        )}
        {management.officers &&
          management.officers.map((office) => OfficerInfo(office))}
      </dl>
    </div>
  );
}

function OfficerInfo(office) {
  let officers = office.hashers.map((hasher) => {
    let value = hasher.name;
    if (hasher.href) {
      value = <a href={`${hasher.href}`}>{hasher.name}</a>;
    } else if (hasher.email) {
      value = <a href={`mailto:${hasher.email}`}>{hasher.name}</a>;
    }
    return <dd>{value}</dd>;
  });
  let officeText = office.title;
  if (office.href) {
    officeText = <a href={office.href}>{officeText}</a>;
  } else if (office.email) {
    officeText = <a href={`mailto:${office.email}`}>{officeText}</a>;
  }
  return (
    <div>
      <dt>{officeText}</dt>
      {officers}
    </div>
  );
}

class WebGeeks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nerds: [],
    };
  }
  componentDidMount() {
    fetch("/data/nerds.json")
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          nerds: result,
        });
      });
  }

  render() {
    const { nerds } = this.state;
    // Active nerds first.
    let sorted_nerds = nerds
      .filter((n) => n.active)
      .concat(nerds.filter((n) => !n.active));
    return (
      <div className="row">
        <div className="col-sm-6 md-2 kennel" key="webgeeks">
          <h2>
            Web Geeks <span className="fas fa-glasses" />
          </h2>
          <dl>
            <dt>Contacts</dt>
            {sorted_nerds.map((nerd) => {
              let className = nerd.active ? "" : "less-nerdy";
              return (
                <dd className={className}>
                  <a href={`mailto:${nerd.hasher.email}`}>{nerd.hasher.name}</a>{" "}
                  - {nerd.what}
                </dd>
              );
            })}
          </dl>
        </div>
      </div>
    );
  }
}
