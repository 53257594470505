import Autolinker from "autolinker";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import gcalPlugin from "@fullcalendar/google-calendar";
import React from "react";
import { toMoment } from '@fullcalendar/moment';
import { Helmet } from "react-helmet";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { Parser as HtmlToReactParser } from "html-to-react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { withRouter, Redirect } from "react-router-dom";
import { addressToCoordinates } from "./location";
import { dateUrlPart, momentDate, monthUrlPart, weekDate, weekUrlPart } from "./time";
import "./Calendar.css";

// TODO: Move to a config file.
const WH3_SOURCES = [
  // Seattle Calendar
  {
    googleCalendarId: "8d65om7lrdq538ksqednh2n648@group.calendar.google.com",
    color: "rgba(230, 138, 0, 0.72)",
    textColor: "#000",
  },
  //HSWTF Hash Calendar
  {
    googleCalendarId: "hswtfh3@gmail.com",
    color: "rgba(230, 138, 0, 0.72)",
    textColor: "#000",
  },
  //Bellingham Hash Calendar
  {
    googleCalendarId: "83i96opmu0s8gfj4g4ahsmch80@group.calendar.google.com",
    color: "rgba(230, 138, 0, 0.72)",
    textColor: "#000",
  },
  //US Holidays
  {
    googleCalendarId: "usa__en@holiday.calendar.google.com",
    textColor: "#000",
    borderColor: "rgba(230, 138, 0, 0.72)",
    backgroundColor: "#FFD54F",
  },
  //Lunar Calendar
  {
    googleCalendarId: "ht3jlfaac5lfd6263ulfh4tql8@group.calendar.google.com",
    textColor: "#000",
    borderColor: "rgba(230, 138, 0, 0.72)",
    backgroundColor: "#FFD54F",
  },
];

const DEFAULT_ICON = "/img/WAH3.png";

function guessKennelImage(title) {
  var icon = null;
  const lowerTitle = title.toLowerCase();
  if (lowerTitle.includes("hswtf")) {
    icon = "HSWTF 01 (patch).png";
  } else if (lowerTitle.includes("nbh3")) {
    icon = "NBH3.png";
  } else if (lowerTitle.includes("psh3")) {
    icon = "PSH3.png";
  } else if (lowerTitle.includes("rain city")) {
    icon = "RCH3.png";
  } else if (lowerTitle.includes("s3h3")) {
    icon = "S3H3.png";
  } else if (lowerTitle.includes("seamon")) {
    icon = "SeaMonH3.png";
  } else if (lowerTitle.includes("sh3")) {
    icon = "SH3.png";
  } else if (lowerTitle.includes("ssh3")) {
    icon = "SSH3.png";
  } else if (lowerTitle.includes("th3")) {
    icon = "TH3.png";
  } else if (lowerTitle.includes("bellinghamster")) {
    icon = "BELLINGHAMSTERH3.png";
  }

  if (icon == null) {
    return DEFAULT_ICON;
  }
  return `/img/icons/${icon}`;
}

function descriptionSnippet(description) {
  if (!description) {
    return "";
  }
  description = description
    .replaceAll(/<br *\/?>/g, "\n")
    .replaceAll(/<[^>]*>/g, "");
  const parts = description.split(/(\r?\n[ \t]*){2}/g);
  if (parts.length > 1) {
    return parts[0];
  }
  // TODO: Make a better default here. Ideal is 2-4 sentences.
  return `${description.substring(0, 200)}...`;
}

class Calendar extends React.Component {
  state = {
    modal: false,
    view: "dayGridMonth",
    event_id: null,
    event: {
      id: "",
      title: "",
      start: new Date(),
      end: new Date(),
      url: "",
      extendedProps: {
        cal_url: "",
        description: "",
      },
    },
    icon: DEFAULT_ICON,
    snippet: "",
    eventContent: "",
    location: "",
    coordinates: null,
    googleCalendarApiKey: "AIzaSyBx1NdDD_wpdKo1-jmrz1SGVhHGOGpmiY4",
    googleMapsApiKey: "AIzaSyDRrLSKXBAsq56qCiGqIn21sHqwoD4DKeg",
    mapBoxToken:
      "pk.eyJ1IjoiZGlja2luYWJveCIsImEiOiJjazRvdmRiYWEzaGtjM25vNm9sNjNid3dwIn0.jnrSmbgxIohgDOm0xkS7_w",
    sources: [],
    nonEventSources: new Set([
      "usa__en@holiday.calendar.google.com",
      "ht3jlfaac5lfd6263ulfh4tql8@group.calendar.google.com",
    ]),
  };

  constructor(props) {
    super(props);
    this.calRef = React.createRef();
    this.state.sources = props.sources ?? WH3_SOURCES;
  }

  componentDidMount() {
    const calendar = this.calRef.current.getApi();

    // Start with the defaults of today and the month view.
    let date = toMoment(calendar.getDate(), calendar);
    let view = "dayGridMonth";
    let event_id = null;

    if (this.props.match.params.event) {
      event_id = this.props.match.params.event;
    }

    if (this.props.match.params.day) {
      date = moment({
        year: parseInt(this.props.match.params.year),
        month: parseInt(this.props.match.params.month, 10) - 1,
        day: parseInt(this.props.match.params.day, 10)
      });
      view = "dayGridDay";
    } else if (this.props.match.params.month) {
      date = moment({
        year: parseInt(this.props.match.params.year),
        month: parseInt(this.props.match.params.month, 10) - 1,
      });
    } else if (this.props.match.params.week) {
      date = weekDate(
        parseInt(this.props.match.params.year),
        parseInt(this.props.match.params.week, 10)
      );
      view = "timeGridWeek";
    }

    this.setState({
      urlPath: this.props.match.url,
      view: view,
      event_id: event_id,
    });

    // Update the calendar to the appropriate date and view based on the URL.
    if (date && !isNaN(date)) {
      calendar.gotoDate(date.toDate());
    }
    calendar.changeView(view);
  }

  toggle = () => {
    const modal = this.state.modal;
    this.setState(
      {
        event_id: modal ? null : this.state.event_id,
        modal: !modal,
      },
      this.updateUrl
    );
  };

  // Triggers when a user clicks on an event.
  handleEventClick = (args) => {
    args.jsEvent.preventDefault();

    // Skip onclick handler for non-hash events.
    const sourceCalId =
      args.event.source.internalEventSource.meta.googleCalendarId;
    if (this.state.nonEventSources.has(sourceCalId)) {
      return;
    }
    this.displayEvent(args.event);
  };

  displayEvent = async (event) => {
    let location = event.extendedProps.location;
    let coordinates = null;

    // These locations are everywhere in older events. Too many to get rid of.
    if (location && location.trim().toUpperCase() === "TBD") {
      coordinates = null;
      location = null;
    }
    if (location) {
      let locationParts = location.split(",");
      if (locationParts.length === 2) {
        let lat = parseFloat(locationParts[0]);
        let long = parseFloat(locationParts[1]);
        if (lat && long) {
          coordinates = [lat, long];
        }
      } else {
        addressToCoordinates(this.state.googleMapsApiKey, location)
          .then((coords) => {
            if (coords) {
              this.setState({
                coordinates: coords,
              });
            }
          })
          .catch((reason) => console.log(reason));
      }
    }

    let eventContent = undefined;
    let snippet = "";
    if (event.extendedProps.description) {
      let description = Autolinker.link(event.extendedProps.description, {
        stripPrefix: false,
        stripTrailingSlash: false,
        className: "ext-link",
      });
      const parser = new HtmlToReactParser();

      // Old events have raw "\n" text due to a bad import.
      if (description.includes("\\n")) {
        description = description.replaceAll("\\n", "\n");
      }
      snippet = descriptionSnippet(description);
      eventContent = parser.parse(description);
    }
    this.setState(
      {
        event: event,
        event_id: event.id,
        eventContent: eventContent,
        snippet: snippet,
        icon: guessKennelImage(event.title),
        coordinates: coordinates,
        location: location,
        modal: true,
      },
      this.updateUrl
    );
  };

  // Triggers whenever the date in the calendar changes.
  // For example, all the navigation buttons cause this.
  updateUrl = () => {
    if (this.calRef.current) {
      const calendar = this.calRef.current.getApi();
      const date = toMoment(calendar.getDate(), calendar);

      // TODO: Figure out how to avoid duplicating this with the routes in App.js.
      let urlPath = "/calendar/";
      const view = calendar.view;
      if (view.type === "dayGridMonth") {
        // Month view.
        urlPath = `/calendar/${monthUrlPart(date)}/`;
      } else if (view.type === "dayGridDay") {
        // Day view
        urlPath = `/calendar/${dateUrlPart(date)}/`;
      } else if (view.type === "timeGridWeek") {
        // Week view
        urlPath = `/calendar/${weekUrlPart(date)}/`;
      }

      // The cal_id urls will default to the day view when modal is exited.
      if (this.state.event_id != null) {
        const eventData = calendar.getEventById(this.state.event_id);

        // If the event isn't returned, the calendar hasn't loaded data yet. Don't update the
        // URL yet to prevent the URL flashing between values.
        if (!eventData) {
          return;
        }
        urlPath = `/calendar/${dateUrlPart(momentDate(eventData.start))}/cal_id/${this.state.event_id}`;
      }

      this.setState({
        urlPath: urlPath,
      });
    }
  };

  modifyEvents = (eventData) => {
    const date = momentDate(eventData.start);
    const year = date.year();
    const month = (date.month() + 1).toString().padStart(2, "0");
    const day = date.date().toString().padStart(2, "0");

    // Save the original URL on a new property, but overwrite with local urls.
    if (!eventData.cal_url) {
      eventData.cal_url = eventData.url;
      eventData.url = `/calendar/${year}/${month}/${day}/cal_id/${eventData.id}`;
    }
  };

  eventsSetHandler = (events) => {
    if (this.state.event_id) {
      const calendar = this.calRef.current.getApi();
      const event = calendar.getEventById(this.state.event_id);
      if (event) {
        this.displayEvent(event);
      }
    }

    // Remove URLs for non-hash events.
    events.forEach((event) => {
      const sourceCalId =
        event.source.internalEventSource.meta.googleCalendarId;
      if (event.url && this.state.nonEventSources.has(sourceCalId)) {
        event._def.url = null;
      }
    });
  };

  nextYear = () => {
    const calendar = this.calRef.current.getApi()
    const view = calendar.view.type;
    if (view === "timeGridWeek") {
      const currentYear = parseInt(this.props.match.params.year, 10);
      const currentWeek = parseInt(this.props.match.params.week, 10);
      const numWeeksNextYear = moment().year(currentYear + 1).weeksInYear();
      let nextWeek = currentWeek;

      // Special case, moving around on the last week of the year.
      if (currentWeek === 52 && numWeeksNextYear === 53) {
        nextWeek = numWeeksNextYear;
      }
      const targetDate = weekDate(currentYear + 1, nextWeek);
      calendar.gotoDate(targetDate.toDate());
      return;
    }
    calendar.nextYear();
  };

  prevYear = () => {
    const calendar = this.calRef.current.getApi()
    const view = calendar.view.type;
    if (view === "timeGridWeek") {
      // The calendar does some unexpected behavior around week navigation because fundamentally it
      // navigates between starting dates, not week numbers.
      // E.g. Clicking prevYear from 2028/week/01 should go to 2027/week/01, not 2026/week/52
      const currentYear = parseInt(this.props.match.params.year, 10);
      const currentWeek = parseInt(this.props.match.params.week, 10);
      const numWeeksPrevYear = moment().year(currentYear - 1).weeksInYear();
      let prevWeek = currentWeek;

      // Special case, moving around on the last week of the year.
      if (currentWeek === 52 && numWeeksPrevYear === 53) {
        prevWeek = numWeeksPrevYear;
      }

      const targetDate = weekDate(currentYear - 1, prevWeek);
      calendar.gotoDate(targetDate.toDate());
      return;
    }
    calendar.prevYear();
  };

  render() {
    return (
      <div className="Calendar">
        {this.state.urlPath && <Redirect to={this.state.urlPath} />}
        <Helmet>
          <title>Seattle-Area H3 | Calendar of Events</title>
          <link
            rel="stylesheet"
            href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
            integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
            crossorigin=""
          />
          <script
            src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"
            integrity="sha512-XQoYMqMTK8LvdxXYG3nZ448hOEQiglfqkJs1NOQV44cWnUrBc8PkAOcXy20w0vlaXaVUearIOBhiXZ5V3ynxwA=="
            crossorigin=""
          ></script>
          <meta property="og:type" content="website" />
        </Helmet>
        <div className="container cal-container text-center pt-2">
          <Modal
            show={this.state.modal}
            onHide={this.toggle}
            dialogClassName="calendar-modal-dialog"
            contentClassName="calendar-modal-content"
          >
            <Helmet>
              <title>{this.state.event.title}</title>
              <meta property="og:title" content={this.state.event.title} />
              <meta property="og:description" content={this.state.snippet} />
              <meta property="og:image" content={this.state.icon} />
            </Helmet>
            <Modal.Header closeButton>
              <div className="event-header">
                <h2>
                  {this.state.event.title}{" "}
                  <a
                    title="Google Calendar"
                    target="_blank"
                    rel="noreferrer"
                    href={this.state.event.extendedProps.cal_url}
                  >
                    <span className="far fa-calendar-alt" />
                  </a>{" "}
                  {this.state.location && (
                    <a
                      title="Directions"
                      target="_blank"
                      rel="noreferrer"
                      href={`https://maps.google.com/?daddr=${this.state.location}`}
                    >
                      <span className="fas fa-directions" />
                    </a>
                  )}
                </h2>
                <div className="event-metadata">
                  <span className="event-start-date">
                    {momentDate(this.state.event.start).format('l')}
                  </span>{" "}
                  {!this.state.event.allDay && (
                    <span className="event-start-time">
                      {momentDate(this.state.event.start).format('LT')}
                    </span>
                  )}
                </div>
              </div>
            </Modal.Header>
            <Modal.Body className="calendar-modal">
              <div className="eventContent">{this.state.eventContent}</div>
              {this.state.coordinates && (
                <div title="Map of start location">
                  <MapContainer
                    key={this.state.event.id}
                    center={this.state.coordinates}
                    zoom={14}
                    scrollWheelZoom={true}
                  >
                    <TileLayer
                      attribution="&copy; <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> &copy; <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>"
                      url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token={accessToken}"
                      accessToken={this.state.mapBoxToken}
                    />
                    <Marker position={this.state.coordinates} />
                  </MapContainer>
                </div>
              )}
            </Modal.Body>
          </Modal>
          <FullCalendar
            ref={this.calRef}
            plugins={[
              dayGridPlugin,
              gcalPlugin,
              interactionPlugin,
              momentTimezonePlugin,
              timeGridPlugin,
            ]}
            timeZone='America/Los_Angeles'
            headerToolbar={{
              left: "dayGridMonth,timeGridWeek,dayGridDay",
              center: "title",
              right: "today prevYear,prev,next,nextYear",
            }}
            initialView={this.state.view}
            eventSources={this.state.sources}
            eventDataTransform={this.modifyEvents}
            eventsSet={this.eventsSetHandler}
            googleCalendarApiKey={this.state.googleCalendarApiKey}
            eventClick={this.handleEventClick}
            contentHeight="auto"
            navLinks={true}
            datesSet={this.updateUrl}
            customButtons={{
              nextYear: {
                click: this.nextYear,
              },
              prevYear: {
                click: this.prevYear,
              }
            }}
          />
          <div className="row subscribe">
            <div className="col-12 text-center">
              <a
                href="https://calendar.google.com/calendar/ical/8d65om7lrdq538ksqednh2n648%40group.calendar.google.com/public/basic.ics"
                className="btn btn-light"
                role="button"
              >
                Subscribe
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const RoutableCalendar = withRouter(Calendar);
