import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import moment from "moment";

import { RoutableCalendar as Calendar } from "./Calendar.js";
import { HtmlSnippetPage, RawHtmlPage, WH3Nav } from "./WH3.js";

export default function DefaultRoute() {
  moment.tz.setDefault("America/Los_Angeles");
  return (
    <Router>
      <WH3Nav />
      <Switch>
        <Route exact strict path="/calendar">
          <Redirect to="/calendar/" />
        </Route>
        <Route
          path={[
            "/calendar/:year([0-9]{4})/:month([0-9]{2})/:day([0-9]{2})/cal_id/:event",
            "/calendar/:year([0-9]{4})/:month([0-9]{2})/:day([0-9]{2})",
            "/calendar/:year([0-9]{4})/:month([0-9]{2})",
            "/calendar/:year([0-9]{4})/week/:week([0-9]{2})",
            "/calendar/",
          ]}
        >
          <Calendar />
        </Route>
        <Route exact strict path="/kennels">
          <Redirect to="/kennels/" />
        </Route>
        <Route exact strict path="/kennels/">
          <HtmlSnippetPage
            className="WH3"
            path="/html_snippets/kennels.html"
            stylesheet="/html_snippets/kennels.css"
            title="Seattle Area H3 | Kennels Info"
          />
        </Route>
        {/* The harelines folder must have the final slash for links to work. */}
        <Route exact strict path="/harelines">
          <Redirect to="/harelines/" />
        </Route>
        <Route exact strict path="/harelines/">
          <HtmlSnippetPage
            className="WH3"
            path="/html_snippets/harelines.html"
            stylesheet="/html_snippets/harelines.css"
            title="Seattle Area H3 | Harelines"
          />
        </Route>
        <Route exact path="/misc">
          <HtmlSnippetPage
            className="WH3"
            path="/html_snippets/misc.html"
            stylesheet="/html_snippets/misc.css"
            title="Seattle Area H3 | Misc Links"
          />
        </Route>
        <Route exact path="/">
          <HtmlSnippetPage
            className="WH3"
            path="/html_snippets/index.html"
            title="Seattle Area Hash House Harriers | A Drinking Club with a Running Problem"
          />
        </Route>
        {/* Hack to prevent infinite nav bars. Real site is better. */}
        <Route
          path="/raw"
          component={() => {
            window.location.href = "/raw/error.html";
            return null;
          }}
        />
        <Route path="*" component={RawHtmlPage} />
      </Switch>
    </Router>
  );
}
