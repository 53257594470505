import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import DefaultRoute from "./App";

const history = createBrowserHistory();

let app = document.getElementById("root");
if (app) {
  // Hack for faster page changes with S3 hosting.
  // See: https://via.studio/journal/hosting-a-reactjs-app-with-routing-on-aws-s3
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  ReactDOM.render(<DefaultRoute />, app);
}
