import { Loader as GoogleApiLoader } from "@googlemaps/js-api-loader";

export function addressToCoordinates(googleApiKey, address) {
  const loader = new GoogleApiLoader({
    apiKey: googleApiKey,
    version: "weekly",
    libraries: ["places"],
  });
  console.log(googleApiKey);
  return new Promise((resolve, reject) => {
    loader
      .load()
      .then(() => {
        const tempMapDiv = window.document.createElement("div");
        const map = new window.google.maps.Map(tempMapDiv);
        const service = new window.google.maps.places.PlacesService(map);
        service.findPlaceFromQuery(
          {
            query: address,
            fields: ["geometry"],
          },
          (results, status) => {
            if (results && results.length > 0) {
              const loc = results[0].geometry.location;
              const coords = [loc.lat(), loc.lng()];
              resolve(coords);
            } else {
              reject(status);
            }
          }
        );
      })
      .catch((error) => reject(error));
  });
}
